<template>
    <div>
        <div v-if="hasResource('home')" v-loading="loading" class="aops-home" >
            <div class="aop-home-statistics">
                <div v-for="item in statisticsList" :key="item.label" class="aop-statistics-item">
                    <div class="aop-home-label">{{item.label}}</div>
                    <div class="aop-statistics-num">{{item.num}}<span>{{item.unit || ''}}</span></div>
                    <div class="aop-statistics-num">
                        <span v-if="item.add || item.add === 0">
                            <span class="aop-label">昨日新增：  </span>{{item.add}}{{item.unit || ''}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="aop-home-deal">
                <div class="aop-deal-item">
                    <div class="aop-home-label">昨日处理</div>
                    <div class="aop-deal-info">
                        <span v-for="item in deal" :key="item.key" class="aop-info-item">
                            {{item.label}} {{statisticsData[item.key] && statisticsData[item.key].y_handled_total || 0}}
                        </span>
                    </div>
                </div>
                <div class="aop-deal-item">
                    <div class="aop-home-label">本月处理</div>
                    <div class="aop-deal-info">
                        <span v-for="item in deal" :key="item.key" class="aop-info-item">
                            {{item.label}} {{statisticsData[item.key] && statisticsData[item.key].m_handled_total || 0}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="aop-home-deal">
                <div class="aop-deal-item">
                    <div class="aop-home-label">待处理</div>
                    <div class="aop-deal-info">
                        <span v-for="item in deal" :key="item.key" class="aop-info-item">
                            {{item.label}} {{statisticsData[item.key] && statisticsData[item.key].wait_handle_total || 0}}
                        </span>
                    </div>
                    <el-table
                        :data="dealList"
                        :show-header="false"
                        style="width: 100%;"
                        max-height="500px"
                    >
                        <el-table-column
                            v-for="col in column"
                            :key="col.prop"
                            :prop="col.prop"
                            :width="col.width"
                            :show-overflow-tooltip="col.showOverflowTooltip"
                        >
                            <template slot-scope="{row}">
                                <div v-if="col.prop === 'handle_type'">
                                    [{{row[col.prop] | dealFilter}}]
                                </div>
                                <div v-else-if="col.prop === 'created_at'">
                                    {{row[col.prop] | dateFilter}}
                                </div>
                                <!-- <div v-else-if="col.prop === 'content'">
                                    {{row.handle_type === 'subscribe' ? row.organization_name : row[col.prop]}}
                                </div> -->
                                <div v-else-if="col.prop === 'operation'">
                                    <el-button
                                        size="mini"
                                        type="text"
                                        @click="handleDeal(row)"
                                    >去处理</el-button>
                                </div>
                                <span v-else>{{row[col.prop] || '--'}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="aop-pagination-wrap">
                        <el-pagination
                            :page-sizes="[10,20,50]"
                            :page-size="pagesize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                            :current-page.sync="currentPage"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="aops-home-no-permission">
                <img :src="noPermissionImg" alt="" class="aop-no-permission-img" />
                <div class="aop-no-permission-dec">无首页数据权限</div>
            </div>
        </div>
        <editPassword />
    </div>
</template>

<script>
import { formatDate } from 'ecoplants-lib';
import homeApi from '@/api/home.js';
import editPassword from './editPassword/editPassword.vue';
import noPermissionImg from '@/assets/images/home-no-permission.png';
import { statistics, deal, column } from './data';

export default {
    components: { editPassword },
    filters: {
        /**
         * 处理类型筛选
         * @param {*} val val
         * @returns {*}
         */
        dealFilter(val) {
            const child = deal.find((item) => item.key === val);
            return child ? child.label : '';
        },
        /**
         * 时间格式化
         * @param {number} val 时间戳
         * @param {number} status 状态
         * @returns {string}
         */
        dateFilter(val) {
            return val ? formatDate(val * 1000, 'yyyy-MM-dd hh:mm:ss') : '--';
        }
    },
    /**
     * data
     * @returns {*}
     */
    data() {
        return {
            // 统计数据
            statisticsData: {},
            // 统计列表
            statisticsList: statistics(),
            // 处理
            deal,
            // 待处理列表
            dealList: [],
            // 待处理表格的列
            column,
            pagesize: 10,
            total: 0,
            currentPage: 1,
            loading: false,
            noPermissionImg,
            noPermissions: false
        };
    },
    /**
     * mounted
     */
    mounted() {
        setTimeout(() => {
            if (this.hasResource('home') === true) {
                this.noPermissions = false;
            } else if (this.hasResource('home') === false) {
                this.noPermissions = true;
            }
        }, 500);
        this.getData();
        this.getHandlelist();
    },
    methods: {
        /**
         * 获取工作台数据
         */
        async getData() {
            this.loading = true;
            try {
                const res = await homeApi.getDashboardData();
                if (res && res.data.code === 10200) {
                    const statics = res.data?.data?.statics || {};
                    this.statisticsData = statics;
                    this.statisticsList = statistics(statics);
                }
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        /**
         * 获取工作台待处理列表
         */
        async getHandlelist() {
            try {
                const res = await homeApi.getDashboardHandlelist({
                    page: this.currentPage,
                    pageSize: this.pagesize
                });
                if (res && res.data.code === 10200) {
                    this.total = Number(res.data.data.total);
                    this.dealList = res.data.data.item || [];
                }
            } catch (error) {
                this.dealList = [];
                this.total = 0;
            }
        },
        /**
         * 去处理,点击跳转到对应详情页
         * @param {*} item item
         */
        handleDeal(item) {
            let url = {};
            if (item.handle_type === 'subscribe') {
                // 跳转订阅申请
                url = {
                    path: '/subscribes-detail',
                    query: {
                        id: item.id
                    }
                };
            } else if (item.handle_type === 'feedback') {
                // 跳转问题反馈
                url = { path: '/customers/feedbacks' };
            } else if (item.handle_type === 'collect_feedback') {
                // 跳转许愿清单
                url = { path: 'customers/wishList' };
            }
            if (url.path) {
                this.$router.push(url);
            }
        },
        /**
         * 分页
         * @param {number} val 一页多少条
        */
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pagesize = val;
            this.getHandlelist();
        },
        /**
         * 分页
         * @param {number} val 页码
        */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getHandlelist();
        }
    }
};
</script>

<style lang='scss'>
@import "./index.less";
</style>
