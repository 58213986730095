<template>
    <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="25%"
        class="aops-edit-password-dialog"
        :modal-append-to-body="false"
        style="min-width: 400px;"
        @close="closeDialog"
    >
        <div class="aop-dialog-content">
            <h1>嗨！</h1>
            <div class="aop-dialog-name">{{userName}}</div>
            <div class="aop-dialog-hint">为保证账户安全，请您更新自己的安全密码</div>
            <div class="aop-pwd-input">
                <el-input
                    v-model="newPwd"
                    class
                    placeholder="输入新密码"
                    type="password"
                    maxlength="20"
                    show-word-limit
                    @input="pwdLength"
                ></el-input>
            </div>
            <div class="aop-pwd-input aop-pwd-second">
                <el-input
                    v-model="confirmPwd"
                    placeholder="确认新密码"
                    type="password"
                    maxlength="20"
                    show-word-limit
                    @input="confirmPwdLength"
                ></el-input>
            </div>
            <el-button class="aop-pwd-button" @click="reset">ok</el-button>
        </div>
    </el-dialog>
</template>

<script>
import api from '@/api/user';

export default {
    /**
     * data
     * @returns {*}
     */
    data() {
        return {
            dialogVisible: false,
            userName: localStorage.getItem('username'),
            newPwd: '',
            confirmPwd: '',
            // 密码正则
            pwdReg: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\S]+$/,
            pubKey:
        '-----BEGIN PUBLIC KEY-----\n'
        + 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDK969z3jGTsguG4jHQgT2Pnk+F\n'
        + 'RCWqpYs9aisvkelztp2vQxIzjyzQecKYC1LUsqtAlVOH7qbS1ET2i5+RLNuBzRW4\n'
        + 'iZcsYnLtpdr4cuBoGww9txwUDXbx9k7AgQCxSi3KEBvdYDktWT/sY3SNYHvlTw2N\n'
        + '9BtS4k1CNaEq8TAL2wIDAQAB\n'
        + '-----END PUBLIC KEY-----'
        };
    },
    /**
     * mounted
     */
    mounted() {
        this.init();
    },
    methods: {
        /**
         * init
         */
        init() {
            const firstLogin = localStorage.getItem('first-login');
            if (firstLogin === 'true') {
                this.dialogVisible = true;
            }
            if (firstLogin === 'fasle') {
                this.dialogVisible = false;
            }
        },
        /**
         * 密码字符限制
         * @returns {boolean}
         */
        pwdLength() {
            if (this.newPwd.length > 19) {
                this.$message('密码长度不超过20个字符！');
                return false;
            }
        },
        /**
         * confirmPwdLength
         * @returns {boolean}
         */
        confirmPwdLength() {
            if (this.confirmPwd.length > 19) {
                this.$message('密码长度不超过20个字符！');
                return false;
            }
        },

        /**
         * 关闭弹窗事件
         */
        closeDialog() {
            if (this.dialogVisible === false) {
                localStorage.setItem('first-login', 'false');
            }
        },
        /**
         * reset
         */
        reset() {
            if (this.newPwd === '') {
                this.$message('请输入新密码！');
                return;
            }
            if (this.confirmPwd === '') {
                this.$message('请输入确认新密码！');
                return;
            }
            if (this.newPwd != this.confirmPwd) {
                this.$message('两次密码输入不一致！');
                return;
            }
            if (
                !this.pwdReg.test(this.newPwd)
        || !this.pwdReg.test(this.confirmPwd)
            ) {
                this.$message('密码必须大于六位数且包含字母和数字！');
                return;
            }

            if (this.newPwd === this.confirmPwd) {
                const jse = new this.$jsEncrypt();
                jse.setPublicKey(this.pubKey);
                const confirmPassword = jse.encrypt(this.confirmPwd);
                const data = {
                    // user_id: localStorage.getItem("uid"),
                    password: confirmPassword
                };
                api.adminUserPwdEdit(data).then((res) => {
                    if (res.data.code === 10200) {
                        this.$message(res.data.message);
                        this.dialogVisible = false;
                        localStorage.setItem('first-login', 'false');
                        this.init();
                    } else {
                        this.$message(res.data.message);
                    }
                });
            }
        }
    }
};
</script>

<style lang='scss'>
@import "./editPassword.less";
</style>
