import axios from '../uitls/require';

export default {
    /**
     * 首页工作台
     * @returns {*}
     */
    getDashboardData() {
        return axios.get('/admin/dashboard/data');
    },
    /**
     * 首页工作台待处理列表
     * @param {*} params params
     * @returns {*}
     */
    getDashboardHandlelist(params) {
        return axios.get('/admin/dashboard/handlelist', { params });
    }
};
