/**
 * 统计数据
 * @param {*} data 统计数据
 * @returns {Array}
 */
export const statistics = (data = {}) => {
    const customer = data.customer || {};
    const pay = data.pay || {};
    return ([{
        label: '注册用户数',
        num: customer.register_total || 0,
        add: customer.y_add_total || 0,
        unit: '人'
    }, {
        label: '付费用户数',
        num: pay.pay_total || 0,
        add: pay.y_add_total || 0,
        unit: '人'
    }, {
        label: '用户付费转化率',
        num: pay.pay_total && customer.register_total ? `${Math.ceil((pay.pay_total / customer.register_total) * 100)}%` : '',
        add: ''
    }]);
};
export const deal = [{
    label: '问题反馈',
    key: 'feedback'
}, {
    label: '许愿清单',
    key: 'collect_feedback'
}, {
    label: '订阅申请',
    key: 'subscribe'
}];

export const column = [{
    prop: 'handle_type',
    width: '110px'
}, {
    prop: 'created_at',
    width: '180px'
}, {
    prop: 'content',
    showOverflowTooltip: true
}, {
    prop: 'operation',
    width: '120px'
}];
